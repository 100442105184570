.f-container {
    width: 100%;
    background-color: #000000;
    border-top: solid 2px #ffffff58;
}

.f-content .text-desc {
    text-align: center;
    display: flex;
    justify-content: center;
}

.f-content .text-desc > a {
    letter-spacing: 2px;
}

.f-content .text-link {
    margin-inline: 5px;
    padding: 0;
}

.f-content {
    margin-block: 10px;
}

.payments {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.payments > img {
    width: 70px;
    height: 100%;
    filter: grayscale(100%);
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.btc:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.3s ease-in-out;
}

.bank:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.3s ease-in-out;
}

.mastercard:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.3s ease-in-out;
}

.payoneer:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.3s ease-in-out;
}

.paypal:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.2s ease-in-out;
}

.visacard:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.2s ease-in-out;
}

@media (max-width: 800px) {
    .copyRight > p {
        display: flex;
        flex-direction: column;
    }

    .copyRight .text-link {
        border: none;
    }

    .payments > img {
        width: 40px;
        height: 100%;
        filter: grayscale(100%);
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
    }
}

@media (max-width: 300px) {
    .payments {
        display: none;
    }

    .f-content {
        margin-block: 0;
    }
}

