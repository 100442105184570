:root {
  --blue: #0a93af;
  --blue-in-black: #0a9fd4;
  --blue-hover: #00d5ff;
  --bg-color: #000000;
}

body {
  background-color: var(--bg-color);
  overflow-x: hidden !important;
}


::-webkit-scrollbar {
  width: 8px; 
}
::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 6px; 
}
::-webkit-scrollbar-thumb:hover {
  background: var(--blue-hover); 
}


.blur {
  background:#0a93af;
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
}

.color2x {
  background: linear-gradient(270deg, #0a93af 50%, #fe00fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colorBlue {
  color: #0a93af;
}

.title {
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 2px;
  color: #ffffff;
  margin-block: 15px;
}

.style-title {
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(270deg, #0a93af 50%, #fe00fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  text-align: center;
}

.text-desc {
  font-size: 1.15rem;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
}

.text-link {
  color: #0a9fd4;
  font-weight: 500;
  text-decoration: none;
  border-bottom: solid 2px transparent;
  padding: 3px 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.text-link:hover {
  color: var(--blue-hover);
  border-bottom: solid 2px var(--blue-hover);
  padding: 3px 5px;
  transition: all 0.3s ease-in-out;
}

.btn {
  border: none;
  border-radius: 4px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.48);
  overflow: hidden;
  padding: 20px 80px;
  margin-block: 60px;
  font-size: 20px;
  position: relative;
  color: #ffffff;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  min-width: max-content;
  -webkit-transition: background-position .7s,box-shadow .4s;
  transition: background-position .7s,box-shadow .4s;
  background-size: 110%;
  font-family: "Poppins", sans-serif;
}
.btn:hover {
  background-position: 0% 30%;
}
.btn:hover:after {
  right: -10px;
  -webkit-transition: right .4s,-webkit-transform 30s .2s linear;
  transition: right .4s,-webkit-transform 30s .2s linear;
  transition: right .4s,transform 30s .2s linear;
  transition: right .4s,transform 30s .2s linear,-webkit-transform 30s .2s linear;
}
.btn:before,.btn:after {
  display: block;
  position: absolute;
}
.btn:before {
  -webkit-transition: all 1s;
  transition: all 1s;
  font-size: 30px;
  left: 25px;
  top: 19px;
}
.btn:after {
  -webkit-transition: right .4s, -webkit-transform .2s;
  transition: right .4s, -webkit-transform .2s;
  transition: right .4s, transform .2s;
  transition: right .4s, transform .2s, -webkit-transform .2s;
  font-size: 100px;
  opacity: .3;
  right: -120px;
  top: -17px;
}

.btn {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset, 0px 0px 10px 0px #0a93af99;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0a93af), to(#fe00fe3d)), url("./images/bg-btn.jpg");
  background-image: linear-gradient(270deg, #0a93af 50%, #fe00fec8 100%), url("./images/bg-btn.jpg");
  background-size: 500px;
  background-repeat: no-repeat;
}

.btn:before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url('./images/bio.png');
  background-size: cover;
  margin-top: -12px;
  margin-inline: -15px;
}

.btn:after {
  content: "";
  display: inline-block;
  width: 100px;
  height: 100px;
  background-image: url('./images/bio.png');
  background-size: cover;
  top: 1;
  margin-right: -10px;
}

.btn:hover {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset, 0px 0px 30px 0px #0a93af;
}
.btn:hover:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.btn:hover:before {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}



@media (max-width: 400px) {
  .text-desc {
      font-size: 1rem;
  }
}

@media (max-width: 300px) {
  .style-title {
    font-size: 1.5rem !important;
  }
}
