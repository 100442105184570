.sm-container {
    padding-block: 10px;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.sm-container .sm-btn-1,.sm-btn-2,.sm-btn-3,.sm-btn-4 {
    margin-block: 0px 80px;
}

.sm-btn-1::before {
    width: 50px;
    height: 50px;
    background-image: url('../../images/Linkedin.png');
    margin-top: -9px;
    margin-inline: -15px;
}

.sm-btn-1::after {
    width: 100px;
    height: 100px;
    background-image: url('../../images/Linkedin.png');
    top: 1;
    margin-right: -10px;
}

.sm-btn-2::before {
    width: 50px;
    height: 50px;
    background-image: url('../../images/Instagram.png');
    margin-top: -9px;
    margin-inline: -15px;
}

.sm-btn-2::after {
    width: 100px;
    height: 100px;
    background-image: url('../../images/Instagram.png');
    top: 1;
    margin-right: -10px;
}

.sm-btn-3::before {
    width: 50px;
    height: 50px;
    background-image: url('../../images/Telegram.png');
    margin-top: -9px;
    margin-inline: -15px;
}

.sm-btn-3::after {
    width: 100px;
    height: 100px;
    background-image: url('../../images/Telegram.png');
    top: 1;
    margin-right: -10px;
}

.sm-btn-4::before {
    width: 50px;
    height: 50px;
    background-image: url('../../images/Gmail.png');
    margin-top: -9px;
    margin-inline: -15px;
}

.sm-btn-4::after {
    width: 100px;
    height: 100px;
    background-image: url('../../images/Gmail.png');
    top: 1;
    margin-right: -10px;
}

@media (max-width: 1100px) {
    .sm-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0;
        margin-block: 30px 50px;
    }

    .sm-container .sm-btn-1,.sm-btn-2,.sm-btn-3,.sm-btn-4 {
        margin: 10px;
        width: 200px;
    }

    .sm-container .btn {
        min-width: 100%;
    }
}