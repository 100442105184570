.ps-container {
    overflow: hidden;
    position: relative;
    margin-block: 50px;
    margin-inline: 1rem;
}

.ps-card {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: 400px;
    margin-bottom: 1.5rem;
    margin: auto;
    transition: all 0.3s ease-in;
    border: solid 2px #ffffff3c;
}

.ps-card > a > img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.ps-card:hover {
    transform: scale(0.98);
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0% ,
        rgba(33, 150, 243, 0.46) 217.91%
    );
    box-shadow: 0px 6px 24px -8px rgba(33, 150, 243, 0.7);
}

.ps-card:hover > a > img {
    filter: brightness(20%);
    transition: all 0.3s ease-in-out;
}

.ps-card:hover .clickHere,.text-click {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.ps-card:hover .text-click {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.clickHere {
    position: absolute;
    width: 100px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.title-cards {
    margin-block: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-icon {
    width: 50px;
    margin-top: 10px;
}

.project-icon {
    width: 60px;
    margin-inline: 1rem;
}

.title-ps {
    display: flex;
    justify-content: center;
    align-items: end;
}

.title-ps .style-title {
    margin-bottom: 5px;
}

.ps-container .text-desc {
    text-align: center;
    margin-left: 2rem;
    margin-block: 10px 50px;
}


.ps-card > a {
    text-decoration: none;
}

.swiper-horizontal {
    overflow: visible;
}

.ps-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    gap: 1rem;
}

.ps-buttons > button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: var(--blue-in-black);
    border: none;
    border-radius: 5px;
    background-color: #ffffff3c;
    border: solid 2px #ffffff3c;
    cursor: pointer;
}

.ps-buttons > :nth-child(1) {
    background-color: transparent;
}

.ps-buttons > :nth-child(2) {
    background-color: #2d2d2d;
    box-shadow: 0px 0px 23px rgba(10, 159, 212, 0.5);
}

.style-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 50px 0px;
    font-size: 2rem;
    background: linear-gradient(270deg, #0a93af 40%, #fe00fe 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleP {
    font-size: 2rem;
    font-weight: 500;
    white-space: nowrap;
}

.text-click {
    color: #ffffffa0;
    position: absolute;
    width: 100px;
    top: 50%;
    left: 51%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}


@media (max-width: 640px) {
    .ps-head {
        align-items: center;
    }

    .ps-buttons {
        position: initial;
    }
}

@media (max-width: 1300px) {
    .ps-container {
        margin-block: 0;
    }

    .titleP {
        font-size: 1.5rem;
        font-weight: 500;
        white-space: nowrap;
    }
    
}

@media (max-width: 1100px) {
    .title-ps > p {
        margin-top: 0;
    }
}

@media (max-width: 530px) {
    .title-ps .style-title {
        margin-bottom: 5px;
        font-size: 1.5rem;
    }    
    
    .project-icon {
        width: 50px;
        margin-inline: 0.5rem;
    }
}

@media (max-width: 300px) {
    .title-ps .style-title {
        margin-bottom: 5px;
        font-size: 1.1rem !important;
    }    
    
    .project-icon {
        width: 40px;
        margin-inline: 0.5rem;
    }
}