.card-container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.card-wrapper {
    flex-wrap: nowrap;
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    width: 300px;
    height: 500px;
    border-radius: 15px;
    overflow: hidden;
    border: solid 2px #ffffff3c;
    flex: 0 0 calc(33.33% - 20px);
}

.card:is(:hover , :focus) .imageLevels {
    scale: calc(1.1);
    transition: all 0.4s ease-in-out;
    filter: brightness(15%);
}

.card:is(:hover , :focus) .iconLevels {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}

.card:is(:hover , :focus) .level-blur {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}

.card .imageLevels {
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.4s ease-in-out;
    position: relative;
}

.card .iconLevels {
    position: absolute;
    z-index: 10;
    width: 150px;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    margin: 5px;
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
}

.level-blur {
    width: 8rem;
    height: 8rem;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(50px);
    z-index: 9;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #090205;
}

.content {
    padding: 5px 25px 10px 25px;
    color: white;
}

.border-top {
    border: solid 1.5px #0a93af;
    width: 50%;
    border-radius: 10px;
}

.content p {
    font-size: 16px;
    margin-top: 8px;
}

.title {
    text-align: center;
    margin-bottom: 0px;
}


@media (max-width: 1300px) {
    .card-container {
        margin-top: 0;
    }

    .card-wrapper {
        flex-wrap: nowrap;
        display: flex;
        gap: 2rem;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 1100px) {
    .card-wrapper {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
    }
    
    .card {
        margin: 0 10px 20px;
    }

    .card {
        position: relative;
        width: 600px;
        height: 500px;
    }
    .card-container {
        margin-top: 70px;
    }

    .card .imageLevels {
        margin-bottom: 100px;
    }
}

@media (max-width: 800px) {
    .card {
        position: relative;
        width: 500px;
        height: 400px;
    }

    .card .imageLevels {
        margin-bottom: 100px;
    }
}

@media (max-width: 700px) {
    .card-container {
        margin-top: 0;
    }

    .card {
        position: relative;
        width: 400px;
        height: 300px;
    }

    .card .imageLevels {
        margin-bottom: 100px;
    }
}

@media (max-width: 530px) {

    .card-container {
        margin-top: 80px !important;
    }

    .card {
        width: 400px;
        height: 300px;
    }

    .card-container .btn {
        margin-block: 15px 60px;
    }
}

@media (max-width: 450px) {

    .card-container {
        margin-top: 0 !important;
    }
}

@media (max-width: 400px) {
    .card {
        position: relative;
        width: 360px;
        height: 200px;
    }

    .card .imageLevels {
        margin-bottom: 100px;
    }

    .content p {
        font-size: 14px;
        margin-top: 8px;
    }

    .level-blur {
        width: 5rem;
        height: 5rem;
    }

    .card .iconLevels {
        width: 120px;
    }
}

@media (max-width: 300px) {
    .card {
        position: relative;
        width: 250px;
        height: 300px;
    }

    .content p {
        font-size: 12px;
        margin-top: 8px;
    }

    .imageLevels {
        margin-bottom: 100px;
    }

    .title .style-title {
        margin-block: 0px 20px;
    }

}

@media (max-width: 526px) {
    .card-container {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .card-container {
        margin-top: 0;
    }
}
  
