.bio-container {
    margin: 5px 30px;
    display: flex;
    flex-direction: row;
}

.bio-right, .bio-left {
    flex: 1;
    width: 50%;
    color: red;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bio-right {
    position: relative;
}

.bio-right .metaBadge {
    position: absolute;
    right: 2%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.metaBadge {
    width: 90px;
}

.img-bio > img {
    width: 600px;
    position: relative;
}

.img-bio {
    float: right;
}

.bio-right .color2x {
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 700;
}

.bio-right .text-desc {
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 60px;
}


@media (max-width: 1410px) {
    .bio-right .metaBadge {
        position: absolute;
        right: 1%;
    }
    
    .metaBadge {
        width: 80px;
    }
}

@media (max-width: 1300px) {
    .metaBadge {
        visibility: hidden;
        display: none;
    }

    .bio-right .text-desc {
        margin-bottom: 15px;
    }
}

@media (max-width: 1100px) {
    .bio-container {
        margin: 5px 30px;
        display: flex;
        flex-direction: column;
    }

    .bio-right, .bio-left {
        flex: 1;
        width: 100%;
        color: red;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .img-bio {
        display: flex;
        justify-content: center;
        align-items: center;
        float: none;
    }

    .img-bio > img {
        width: 500px;
    }
    
}

@media (max-width: 600px) {
    .img-bio > img {
        width: 350px;
    }

    .bio-container {
        margin: 5px 10px;
    }

    .bio-right .br {
        display: none;
    }
    
}

@media (max-width: 300px) {
    .img-bio > img {
        width: 250px;
        margin-right: 1.5rem;
    }
}