.hero {
    background: url(../../images/bg1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.meHero-sm {
    margin-inline: auto;
    width: 500px;
    margin-top: -20px;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    
}

.meHero {
    margin-inline: auto;
    width: 1350px;
    margin-top: -20px;
    position: relative;
    pointer-events: none;
}

.heroBack {
    position: absolute;
    margin-inline: auto;
    width: 1450px;
    margin-top: -20px;
    pointer-events: none;
    opacity: 1;
}

.meHeroMobile {
    margin-inline: auto;
    width: 100%;
    margin-top: -20px;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    
}

.hero-blur {
    width: 50rem;
    height: 40rem;
    top: -65%;
    filter: blur(90px);
    opacity: 0.8;
}

.h2Name {
    color: #ffff;
    font-weight: 800;
    position: absolute;
    z-index: 1;
    bottom: 0;
    font-size: 4rem;
}

.figures {
    position: absolute;
    color: #ffff;
    text-transform: uppercase;
    display: flex;
    gap: 50rem;
    justify-content: space-between;
    bottom: 30px;
}

.figures div > span {
    display: flex;
}

.figures div > span:nth-of-type(1) {
    color: #ffff;
    font-size: 3rem;
    font-weight: 500;
}

.figures div > span:nth-of-type(2) {
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 2px;
}

@media (min-width: 2000px) {
    .meHero {
        margin-inline: auto;
        width: 1800px;
        margin-top: -20px;
        position: relative;
        pointer-events: none;
    }

    .heroBack {
        width: 1900px;
    }
}

@media (max-width: 1950px) {
    .hero-blur {
    width: 30rem;
    height: 40rem;
    top: -75%;
    }

    .meHero {
        margin-inline: auto;
        width: 1300px;
        margin-top: -20px;
        position: relative;
        pointer-events: none;
    }
}

@media (max-width: 1500px) {
    .hero-blur {
    width: 30rem;
    height: 40rem;
    top: -75%;
    }

    .meHero {
        margin-inline: auto;
        width: 1100px;
        margin-top: -20px;
        position: relative;
        pointer-events: none;
    }
}

@media (max-width: 1300px) {
    .figures div > span {
        text-align: center;
    }

    .figures > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .meHero {
        margin-inline: auto;
        width: 1100px;
        margin-top: -15px;
        position: relative;
        pointer-events: none;
    }

}

@media (max-width: 1100px) {
    .meHero {
        display: none;
    }

    .meHero-sm {
        margin-inline: auto;
        width: 800px;
        margin-top: -160px;
        position: absolute;
        pointer-events: none;
        visibility: visible;
    }

    .figures {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0;
        top: 80%;
    }

    .figures > div {
        padding: 2rem;
    }

}

@media (max-width: 1000px) {
    .meHero {
        width: 0px;
        visibility: hidden;
    }

    .figures {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }

    .figures > div {
        padding-inline: 20px;
    }

    .hero-blur {
        width: 40rem;
        height: 30rem;
        top: -55%;
    }

}

@media (max-width: 800px) {
    .meHero-sm {
        width: 700px;
    }

    .hero-blur {
        width: 35rem;
        height: 25rem;
        top: -40%;
    }
}

@media (max-width: 700px) {
    .meHero-sm {
        width: 100%;
        margin-top: -80px;
    }

    .figures {
        top: 90%;
    }

    .hero-blur {
        width: 25rem;
        height: 15rem;
        top: -20%;
    }
}

@media (max-width: 500px) {
    .hero-blur {
        width: 15rem;
        height: 10rem;
        top: -10%;
    }

    .meHero-sm {
        top: 80px;
    }
    
    .figures {
        top: 80%;
    }
}

@media (max-width: 300px) {
    .figures div > span:nth-of-type(1) {
        color: #ffff;
        font-size: 1rem;
        font-weight: 500;
    }
    
    .figures div > span:nth-of-type(2) {
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 2px;
    }

    .hero-blur {
        width: 10rem;
        height: 5rem;
        top: -50px;
        filter: blur(50px);
    }
}

@media (max-width: 1450px) {
    .heroBack {
        width: 1250px;
        margin-top: -10px;
        right: 20px;
    }

    .hero-blur {
        width: 15rem;
        height: 10rem;
        top: -80px;
    }
}

@media (max-width: 1300px) {
    .heroBack {
        width: 1100px;
        margin-right: 10px;
        margin-top: -60px;
    }
}

@media (max-width: 1200px) {
    .heroBack {
        width: 1000px;
        margin-right: 20px;
    }
}

@media (max-width: 1100px) {
    .heroBack {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 526px) {
    .meHeroMobile {
        visibility: visible;
        margin-top: -90px;
    }

    .meHero-sm {
        visibility: hidden;
        
    }

    .meHero {
        visibility: hidden;
        
    }

    .figures {
        margin-top: 70px;
        top: 90%;
    }

}

@media (max-width: 500px) {
    .meHeroMobile {
        visibility: visible;
        top: 0;
    }

    .figures {
        top: 102%;
        margin-top: 0;
    }

}

@media (max-width: 450px) {
    .meHeroMobile {
        visibility: visible;
        top: 40px;
    }

    .figures {
        top: 80%;
        margin-top: 0;
    }

    .hero-blur {
        width: 20rem;
        height: 10rem;
        top: -70px;
    }

}

@media (max-width: 400px) {
    .meHeroMobile {
        visibility: visible;
        margin-top: -50px;
    }

    .figures {
        top: 80%;
        margin-top: 0;
    }

    .hero-blur {
        width: 15rem;
        height: 10rem;
        top: -100px;
    }

}

@media (max-width: 290px) {
    .meHeroMobile {
        visibility: visible;
        margin-top: -80px;
    }

    .figures {
        top: 80%;
        margin-top: 0;
    }

    .hero-blur {
        width: 15rem;
        height: 5rem;
        top: -30px;
    }

}
