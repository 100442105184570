.cs-container {
    overflow: hidden;
    position: relative;
    margin-block: 50px;
}

.cs-card {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: 300px;
    margin-bottom: 1.5rem;
    margin: auto;
    transition: all 0.3s ease-in;
    border: solid 2px #ffffff3c;
}

.cs-card > a > img {
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.cs-card:hover {
    transform: scale(0.98);
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0% ,
        rgba(33, 150, 243, 0.46) 217.91%
    );
    box-shadow: 0px 6px 24px -8px rgba(33, 150, 243, 0.7);
}

.cs-card:hover > a > img {
    filter: brightness(20%);
    transition: all 0.3s ease-in-out;
}

.cs-card:hover .clickHere {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.clickHere {
    position: absolute;
    width: 100px;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.title-cards {
    margin-block: 5px;
}

.cs-card > a {
    text-decoration: none;
}

.swiper-horizontal {
    overflow: visible;
}

.cs-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    gap: 1rem;
}

.cs-buttons > button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: var(--blue-in-black);
    border: none;
    border-radius: 5px;
    background-color: #ffffff3c;
    border: solid 2px #ffffff3c;
    cursor: pointer;
}

.cs-buttons > :nth-child(1) {
    background-color: transparent;
}

.cs-buttons > :nth-child(2) {
    background-color: #2d2d2d;
    box-shadow: 0px 0px 23px rgba(10, 159, 212, 0.5);
}

.cs-container p {
    font-weight: 300;
    margin: 0px 0px 30px 0px;
    text-transform: uppercase;
}


@media (max-width: 640px) {
    .cs-head {
        align-items: center;
    }

    .cs-buttons {
        position: initial;
    }
}

@media (max-width: 1300px) {
    .cs-container {
        margin-block: 10px;
    }
}

@media (max-width: 530px) {
    .cs-container p {
        margin-inline: 10px;
    }
}