.certificate-container {
    margin-top: 20px;
    padding-inline: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.certificate-container > p {
    margin-bottom: 0;
}

.certificate {
    margin: 50px;
}

.certificate img {
    width: 800px;
    border-radius: 30px;
    box-shadow: 0px 0px 30px 6px rgba(10, 147, 175, 0.8); 
}

.school-icon {
    width: 25px;
    height: 100%;
    margin-bottom: 2px;
}

.verify {
    display: flex;
    gap: 0.2rem;
    align-items: center;
}

.br {
    display: none;
}


@media (max-width: 1300px) {
    .certificate-container {
        margin-top: 0;
    }
    
}

@media (max-width: 1100px) {
    .certificate-container .style-title {
        margin-top: 0;
    }

    .certificate {
        margin: 30px;
        display: flex;
        justify-content: center;
    }

    .certificate img {
        width: 80%;   
    }
}

@media (max-width: 700px) {
    .certificate-container {
        padding-inline: 20px;
    }

    .br {
        display: block;
    }

    .text-desc-mobile {
        font-size: 1rem;
        text-align: center;
    }

    .text-mobile {
        font-size: 0.9rem;
    }

    .certificate img {
        width: 100%;  
        border-radius: 10px;  
    }
}

@media (max-width: 300px) {
    .certificate-container {
        padding-inline: 10px;
    }
}